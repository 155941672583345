import request from '@/utils/request'

// 查询商品-品牌列表
export function listGoodsBrand(query) {
  return request({
    url: '/platform/goods-brand/list',
    method: 'get',
    params: query
  })
}

// 查询商品-品牌分页
export function pageGoodsBrand(query) {
  return request({
    url: '/platform/goods-brand/page',
    method: 'get',
    params: query
  })
}

// 查询商品-品牌详细
export function getGoodsBrand(data) {
  return request({
    url: '/platform/goods-brand/detail',
    method: 'get',
    params: data
  })
}

// 新增商品-品牌
export function addGoodsBrand(data) {
  return request({
    url: '/platform/goods-brand/add',
    method: 'post',
    data: data
  })
}

// 修改商品-品牌
export function updateGoodsBrand(data) {
  return request({
    url: '/platform/goods-brand/edit',
    method: 'post',
    data: data
  })
}

// 删除商品-品牌
export function delGoodsBrand(data) {
  return request({
    url: '/platform/goods-brand/delete',
    method: 'post',
    data: data
  })
}
